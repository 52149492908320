(function () {
  const openPhotoSwipe = function () {
    const pswpElement = document.querySelectorAll(".pswp")[0];
    const items = [
      {
        src: "assets/galerie/01.jpeg",
        w: 1440,
        h: 1447,
        title: '<div class="title">Bouquetin des Alpes</div><span>50/50</span>',
      },
      {
        src: "assets/galerie/02.jpeg",
        w: 2861,
        h: 2994,
        title: '<div class="title">Le Cerf des bois</div><span>50/50</span>',
      },
      {
        src: "assets/galerie/03.jpeg",
        w: 3917,
        h: 1926,
        title:
          '<div class="title">Brenleire & Folliéran</div><span>80/40</span>',
      },
      {
        src: "assets/galerie/04.jpeg",
        w: 2787,
        h: 2765,
        title: '<div class="title">Highland</div><span>100/100</span>',
      },
      {
        src: "assets/galerie/05.jpeg",
        w: 2955,
        h: 3910,
        title:
          '<div class="title">Montagnes enneigées et renards</div><span></span>',
      },
      {
        src: "assets/galerie/06.jpeg",
        w: 4032,
        h: 3024,
        title:
          '<div class="title">Montagnes enneigées et renards</div><span></span>',
      },
      {
        src: "assets/galerie/07.jpeg",
        w: 640,
        h: 439,
        title: '<div class="title">Nuit d\'hiver étoilée</div><span></span>',
      },
      {
        src: "assets/galerie/follieran-2.jpg",
        w: 1600,
        h: 1268,
        title: '<div class="title">Folliéran</div><span>40/50</span>',
      },
      {
        src: "assets/galerie/moleson-2.jpg",
        w: 1600,
        h: 1290,
        title: '<div class="title">Moléson</div><span>40/50</span>',
      },
      {
        src: "assets/galerie/le_grand_cerf_100_120.jpg",
        w: 2600,
        h: 2177,
        title: '<div class="title">Le grand cerf</div><span>100/120</span>',
      },
      {
        src: "assets/galerie/pierre_de_lune_rose.jpg",
        w: 2600,
        h: 2588,
        title:
          '<div class="title">Pierre de lune rosé</div><span>100/100</span>',
      },
      {
        src: "assets/galerie/2-ctableau-1-opt.jpg",
        w: 1500,
        h: 1513,
        title: '<div class="title">Émeraude</div><span>100/100</span>',
      },
      {
        src: "assets/galerie/les_rochers_de_feu.jpg",
        w: 3000,
        h: 1459,
        title: '<div class="title">Les rochers de feu</div><span>140/70</span>',
      },
      {
        src: "assets/galerie/1-1-champs_min.jpg",
        w: 2000,
        h: 1333,
        title: '<div class="title">Le combat de cerf</div><span>140/70</span>',
      },
      {
        src: "assets/galerie/vache_verte2.jpg",
        w: 3000,
        h: 2975,
        title: '<div class="title">Esmeralda</div><span>100/100</span>',
      },
      {
        src: "assets/galerie/arbre.jpg",
        w: 2958,
        h: 2953,
        title: '<div class="title">Le chêne</div><span>50/50</span>',
      },
      {
        src: "assets/galerie/montagnes.jpg",
        w: 4796,
        h: 2349,
        title: '<div class="title">Le mont d\'or</div><span>220/110</span>',
      },
      {
        src: "assets/galerie/1-matuzon1.jpg",
        w: 1553,
        h: 1035,
        title: '<div class="title">Le brame gris</div><span>150/100</span>',
      },
      {
        src: "assets/galerie/2-btableau-2-opt.jpg",
        w: 1500,
        h: 990,
        title: '<div class="title">Le brame gris</div><span>150/100</span>',
      },
      {
        src: "assets/galerie/4-deux_cerfs.jpg",
        w: 2000,
        h: 1344,
        title: '<div class="title">Faon et cerf</div><span>80/50</span>',
      },
      {
        src: "assets/galerie/2-gris_001.jpg",
        w: 3385,
        h: 3463,
        title: '<div class="title">Le cerf gris</div><span>100/100</span>',
      },
      {
        src: "assets/galerie/1-1matuzon_3.jpg",
        w: 1500,
        h: 749,
        title: '<div class="title">Les gast</div><span>80/40</span>',
      },
      {
        src: "assets/galerie/3-cerf.jpg",
        w: 1000,
        h: 1005,
        title: '<div class="title">Daim</div><span>80/80</span>',
      },
      {
        src: "assets/galerie/6-bouquetin.jpg",
        w: 1000,
        h: 993,
        title: '<div class="title">Bouquetin</div><span>50/50</span>',
      },
      {
        src: "assets/galerie/7-girs002.jpg",
        w: 5369,
        h: 3602,
        title: '<div class="title">Le brame du cerf</div><span>150/100</span>',
      },
      {
        src: "assets/galerie/5-chambre_2_min.jpg",
        w: 1000,
        h: 1500,
        title: '<div class="title">Le cerf d\'or</div><span>100/100</span>',
      },
    ];

    const options = {
      index: 0,
      bgOpacity: 0.95,
      // hide share button
      shareEl: false,
      // hide fullscreen button
      fullscreenEl: false,
    };

    // Initializes and opens PhotoSwipe
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    );
    gallery.init();
  };
  const button = document.querySelector("#gallerylink");
  button.addEventListener("click", openPhotoSwipe);
})();
